<template lang="pug">
.template-chooser-recommended-use-cases(v-if="recommendedUseCases.length")
  .d-flex.justify-content-between.align-items-end.mb-6
    .collection-previews-heading.collection-previews-heading--title
      om-heading(h3) {{ $t('recommendedUseCases') }}
    .collection-previews-heading
      om-link.text-nowrap(primary withIconRight @click="navigateToUseCases()") {{ $t('browseUseCases') }}
  .d-flex.row
    .col-4(v-for="useCase in recommendedUseCases.slice(0, 3)" v-if="useCase && useCase._id")
      UseCaseCard(
        @click="$router.push({ name: 'chooser-use-case', params: { slug: useCase.slug } })"
        :key="useCase._id"
        :image="useCase.image"
        :title="useCase.name"
        :description="useCase.description"
        :class="{ 'special-case': specialCaseIds.includes(useCase._id) }"
      )
        template(#learnMore)
          .d-flex.justify-content-end
            om-link.text-nowrap(
              primary
              withIconRight
              @click.stop="navigateToUseCases(useCase.slug)"
              :data-track-property="`eventName:template_preview|location:main|component:learn_more|item:${useCase.name}`"
            ) {{ $t('learnMore') }}
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'RecommendedUseCases',
    components: {
      UseCaseCard: () => import('@/components/TemplateChooser/components/cards/UseCaseCard.vue'),
    },
    data: () => ({
      specialCaseIds: ['64c2cc4e957a9c002eac19e3', '65ba19dc39eb6000240f7e02'],
    }),
    computed: {
      ...mapGetters('useCase', ['recommendedUseCases']),
    },
    methods: {
      navigateToUseCases(slug) {
        const to = { name: 'chooser-use-cases' };

        if (slug) {
          to.name = 'chooser-use-case';
          to.params = { slug };
        }

        this.$router.push(to);
      },
    },
  };
</script>

<style lang="sass">
  .special-case
      img
        padding: 0 !important
</style>
